import * as React from 'react'
import Layout from '../components/layout'
// import { Link } from 'gatsby'

const Social = () => {
    return (
        <Layout pageTitle="Działalność Społeczna">
            <p className="px-10 pb-10 text-justify">
                <strong>„Dobrą Markę”</strong> – Działalność społeczna</p>

        </Layout>
    )
}

export default Social